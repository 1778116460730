import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class EventsDataService {

  constructor(
    private http: HttpClient
  ) { }

  public getEvents(query = {filter: '{}'}): Observable<any> {
    return this.http.get('events', {params: query});
  }

  public createEvent(payload) {
    return this.http.post('events', payload);
  }

  public updateEvent(eventId, payload) {
    return this.http.put(`events/${eventId}`, payload);
  }

  public deleteOne(eventId) {
    return this.http.delete(`events/${eventId}`);
  }

  public getEventInstances(filter: any): Observable<any> {
    return this.http.post('events/instances', filter);
  }
}
