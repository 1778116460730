import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-feature-page',
  templateUrl: './feature-page.component.html',
  styleUrls: ['./feature-page.component.scss']
})
export class FeaturePageComponent implements OnInit {

  public config;
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    switch (this.activatedRoute.snapshot.routeConfig.path) {
      case 'email-notification':
        this.config = {
          title: 'Email notification',
          tags: ['Notifications', 'Email'],
          images: [
            {
              sourcePath: 'assets/images/Report1.png',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/Report2.png',
              type: 'full'
            }
          ]
        };
        break;
      case 'event-manager':
        this.config = {
          title: 'Event manager',
          tags: ['Events'],
          images: [
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
          ]
        };
        break;
      case 'realtime-occupancy':
        this.config = {
          title: 'Realtime Occupancy',
          tags: ['Realtime', 'Occupancy'],
          images: [
            {
              sourcePath: 'assets/images/Realtime1.png',
              type: 'full'
            },
          ]
        };
        break;
      case 'weather-impact':
        this.config = {
          title: 'Weather Impact',
          tags: ['Weather'],
          images: [
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
          ]
        };
        break;
      case 'working-hours':
        this.config = {
          title: 'Working Hours',
          tags: [],
          images: [
            {
              sourcePath: 'assets/images/WorkingHours1.png',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/WorkingHours2.png',
              type: 'full'
            },
          ]
        };
        break;
      case 'top-bottom-performance':
        this.config = {
          title: 'Top to Bottom Performance',
          tags: [],
          images: [
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
          ]
        };
        break;
      case 'people-counting':
        this.config = {
          title: 'People Counting',
          tags: [],
          images: [
            {
              sourcePath: 'assets/images/PeopleCounting1.png',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/PeopleCounting2.png',
              type: 'full'
            }
          ]
        };
        break;
      case 'children-counting':
        this.config = {
          title: 'Children Counting',
          tags: [],
          images: [
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
          ]
        };
        break;
      case 'custom-counting-hardware':
        this.config = {
          title: 'Custom Counting Hardware',
          tags: [],
          images: [
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
          ]
        };
        break;
      default:
        this.config = {
          title: 'Feature Name',
          tags: ['Tag 1', 'Tag 2'],
          images: [
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
            {
              sourcePath: 'assets/images/people_counting.jpg',
              type: 'full'
            },
            {
              sourcePath: ['assets/images/people_counting.jpg', 'assets/images/people_counting.jpg'],
              type: 'half'
            },
          ]
        };
    }
  }

}
