import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../helpers/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {

  constructor(
    private router: Router,
    private storageService: StorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.storageService.get('user');
    if (currentUser) {
      if (route.data.allowedRoles) {
        if (!route.data.allowedRoles.includes(currentUser.role)) {
          this.router.navigate(['views/day']);
          return false;
        }
      }

      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth']);
      return false;
    }
  }
}
