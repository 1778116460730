<form [formGroup]="entitiesForm" (ngSubmit)="onSubmit()" fxFlex="100%" fxLayoutAlign="space-between" class="asdfg" fxLayout="column">
  <app-loading *ngIf="!entitiesLoaded"></app-loading>
  <div fxLayout="row">
    <ng-select *ngIf="entitiesLoaded"
               [items]="entities"
               [closeOnSelect]="true"
               bindLabel="name"
               bindValue="_id"
               appendTo="body"
               placeholder="Select entity"
               formControlName="entityId"
               appearance="outline"
               fxFlex="100%"
    >
    </ng-select>
  </div>
  <button mat-raised-button color="primary" class="generate__button">See live view</button>
</form>
