<div class="form__dialog" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="flex-end center">
    <fa-icon
      class="icon__item"
      [icon]="['far', 'window-close']"
      matDialogClose
    ></fa-icon>
  </div>

  <div class="dialog__content" fxLayout="column">
    <div
      fxLayout="row"
      class="title__container"
      fxLayoutAlign="space-between flex-end"
    >
      <div class="dialog__title">{{ title }}</div>
    </div>

    <form class="feeback_form" mat-form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutAlign="space-between">

      <mat-form-field class="form__fields" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="20"
        ></textarea>
        <mat-error *ngIf="feedbackForm.controls.description.hasError('required')">
          This field is required.
        </mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <button mat-stroked-button color="primary" class="modal__buttons">
          Submit
        </button>
        <button
          mat-stroked-button
          type="button"
          class="modal__buttons"
          matDialogClose
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
