import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthDataService } from '../../services/data/auth.data.service';
import { StorageService } from 'src/app/core/services/helpers/storage.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from 'src/app/core/services/helpers/snackbar.service';
import { throwError } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { LoginDialogComponent } from '../../../shared/components/login-dialog/login-dialog.component';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  public title = 'Lorem ipsum dolor sit amet';
  public subtitle = 'Ut enim ad minim veniam';
  public sidebarItems = [
    {
      title: 'COMING SOON',
      link: '/'
    }, {
      title: 'INFO',
      link: '/'
    }, {
      title: 'MARKETS',
      link: '/'
    }, {
      title: 'CONTACT',
      link: '/auth/contact'
    }
  ];
  public gridItems = [
    {
      link: 'asd',
      title: 'Titlu 1',
      tags: ['Tag 1', 'Tag 2'],
      imgSrc: 'assets/images/people_counting.jpg',
      body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
      link: 'asd',
      title: 'Titlu 2',
      tags: ['Tag 3', 'Tag 4'],
      imgSrc: 'assets/images/people_counting.jpg',
      body: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      link: 'asd',
      title: 'Titlu 3',
      tags: ['Tag 5', 'Tag 6'],
      imgSrc: 'assets/images/people_counting.jpg',
      body: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
    },
    {
      link: 'asd',
      title: 'Titlu 4',
      tags: ['Tag 7', 'Tag 8'],
      imgSrc: 'assets/images/people_counting.jpg',
      body: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    }
  ];
  public isMobile = false;

  constructor(
    private authDataService: AuthDataService,
    private storageService: StorageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    if (window.screen.width <= 991 || window.innerWidth <= 991) {
      this.isMobile = true;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.authDataService.loginUser(this.loginForm.value).pipe(
      catchError(e => {
        this.loading = false;
        if (e.statusCode === 401) {
          this.snackbarService.showErrorMessage('Invalid username or password');
        } else {
          this.snackbarService.showErrorMessage(e.message);
        }
        return throwError(e);
      })
    )
    .subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  redirectTo(link) {
    this.router.navigate([`${link}`]);
  }

  openLoginDialog() {
    this.dialog.open(LoginDialogComponent);
  }

}
