<div class="form__dialog">
  <div fxLayout="row" fxLayoutAlign="flex-end center">
    <fa-icon
      class="icon__item"
      [icon]="['far', 'window-close']"
      matDialogClose
    ></fa-icon>
  </div>

  <div class="dialog__content">
    <div
      fxLayout="row"
      class="title__container"
      fxLayoutAlign="space-between flex-end"
    >
      <div class="dialog__title">Notifications</div>
    </div>
    <div class="notifications__container" fxLayout="column" fxLayoutGap="10px">
      <div class="no__notifications" *ngIf="!data.notifications.length" fxLayout="row" fxLayoutAlign="center center">
        There are currently no notifications for you.
      </div>
      <mat-card *ngFor="let notification of data.notifications"
                (click)="openNotificationDetails(notification)"
                class="notification__row"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                matBadge="N"
                matBadgePosition="before"
                [matBadgeHidden]="!!notification.read"
      >
        <span class="notification__title" fxFlex="60%">{{notification.title}}</span>
        <fa-icon class="icon__item"
                 [icon]="['fas', 'info-circle']"
        >
        </fa-icon>
      </mat-card>
    </div>


  </div>
</div>
