import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenavContainer } from '@angular/material/sidenav';

import { NotificationsDataService } from '../../services/data/notifications.data.service';
import { UsersDataService } from '../../services/data/users.data.service';
import { NotificationService } from '../../services/helpers/notification.service';
import { StorageService } from '../../services/helpers/storage.service';

import { NotificationsDialogComponent } from '../../../shared/components/notifications-dialog/notifications-dialog.component';

import { Constants } from '../../models/constants';
import { forkJoin } from 'rxjs';
import { NotificationEntity } from '../../entities/notification.entity';
import { UserReadNotificationEntity } from '../../entities/user-read-notification.entity';
import { FeedbackDialogComponent } from '../../../shared/components/feedback-dialog/feedback-dialog.component';
import { EntitySelectorDialogComponent } from '../../../shared/components/entity-selector-dialog/entity-selector-dialog.component';

const ROLES = Constants.ROLES;
@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit {

  @ViewChild(MatSidenavContainer) matSidenavContainer: MatSidenavContainer;

  public isMobile = false;
  public user;
  public notifications;
  public newNotifications = 0;
  public notificationIdToStatusMap = {};
  public roles;

  public navbarItems = [
    {
      title: 'Views',
      type: 'parent',
      icon: 'chart-pie',
      expanded: false,
      route: '/views',
      children: [
        {
          title: 'Day',
          type: 'link',
          route: '/views/day'
        },
        {
          title: 'Week',
          type: 'link',
          route: '/views/week'
        },
        {
          title: 'Month',
          type: 'link',
          route: '/views/month'
        },
        {
          title: 'Year',
          type: 'link',
          route: '/views/year'
        },
        {
          title: 'Live',
          type: 'parent',
          children: [
            {
              title: 'List',
              type: 'link',
              route: '/views/live'
            },
            {
              title: 'View',
              type: 'dialog',
              dialog: EntitySelectorDialogComponent,
              config: {
                width: '50%',
                height: '30%'
              }
            }
          ]
        }
      ]
    },
    {
      title: 'System',
      type: 'parent',
      icon: 'cog',
      expanded: false,
      route: '/system',
      allowedRoles: ROLES.gtUser,
      children: [
        {
          title: 'Webcams',
          type: 'link',
          route: '/system/webcams',
          allowedRoles: [ROLES.admin],
        },
        {
          title: 'Entities',
          type: 'link',
          route: '/system/entities',
          allowedRoles: [ROLES.admin, ROLES.supervisor, ROLES.pSupervisor]
        },
        {
          title: 'Users',
          type: 'link',
          route: '/system/users',
          allowedRoles: ROLES.gtUser,
        },
        {
          title: 'Restore',
          type: 'link',
          route: '/system/restore',
          allowedRoles: [ROLES.admin]
        },
        {
          title: 'Products',
          type: 'link',
          route: '/system/products',
          allowedRoles: [ROLES.admin]
        },
        {
          title: 'Events and Promos',
          type: 'link',
          route: '/system/events',
          allowedRoles: [ROLES.admin]
        },
        {
          title: 'Views',
          type: 'parent',
          expanded: false,
          route: '/system/views',
          allowedRoles: [ROLES.admin],
          children: [
            {
              title: 'Day',
              type: 'link',
              route: '/system/views/day',
              allowedRoles: [ROLES.admin]
            },
            {
              title: 'Week',
              type: 'link',
              route: '/system/views/week',
              allowedRoles: [ROLES.admin]
            },
            {
              title: 'Month',
              type: 'link',
              route: '/system/views/month',
              allowedRoles: [ROLES.admin]
            },
            {
              title: 'Year',
              type: 'link',
              route: '/system/views/year',
              allowedRoles: [ROLES.admin]
            },
            {
              title: 'Live',
              type: 'link',
              route: '/system/views/live',
              allowedRoles: [ROLES.admin]
            }
          ]
        },
        {
          title: 'Reports',
          type: 'link',
          route: '/system/reports',
          allowedRoles: ROLES.gtUser
        },
        {
          title: 'Notifications',
          type: 'link',
          route: '/system/notifications',
          allowedRoles: ROLES.admin
        },
        {
          title: 'API Clients',
          type: 'link',
          route: '/system/api-clients',
          allowedRoles: ROLES.admin
        }
      ]
    }
  ];

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private notificationsDataService: NotificationsDataService,
    private notificationService: NotificationService,
    private userDataService: UsersDataService,
    private storageService: StorageService,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  ngOnInit() {
    this.user = this.storageService.get('user');
    const notificationsFilter = {filter: JSON.stringify({
        roleIds: this.user.roleId
      })};

    this.notificationService.newNotificationCount.subscribe((count: number) => {
      this.newNotifications = count;
    });

    forkJoin([
      this.notificationsDataService.getNotifications(notificationsFilter),
      this.notificationsDataService.getUserReadNotifications(this.user.id)
    ]).subscribe(([notifications, userReadNotifications]: [NotificationEntity[], UserReadNotificationEntity[]]) => {
      userReadNotifications.forEach((userReadNotification) => {
        this.notificationIdToStatusMap[userReadNotification.notificationId] = true;
      });

      this.notifications = notifications;
      this.notifications.forEach((notification) => {
        if (this.notificationIdToStatusMap[notification._id]) {
          notification.read = true;
        } else {
          this.newNotifications++;
        }
      });

      this.notificationService.setNewNotificationCount(this.newNotifications);
    });

    this.userDataService.getRoles().subscribe((roles) => {
      this.roles = roles;
    });
  }

  // countNewNotifications() {
  //   this.newNotifications = 0;
  //
  //   if (this.notifications) {
  //     this.notifications.forEach((notification) => {
  //       if (!this.notificationIdToStatusMap[notification._id]) {
  //         this.newNotifications++;
  //       }
  //     });
  //   }
  //
  //   return this.newNotifications;
  // }

  logout() {
    this.storageService.remove('token');
    this.storageService.remove('user');
    this.router.navigate(['auth']);
  }

  openNotifications() {
    const dialogRef = this.dialog.open(NotificationsDialogComponent, {
      disableClose: true,
      data: {
        notifications: this.notifications,
        newNotifications: this.newNotifications,
        roles: this.roles
      }
    });
  }

  openFeedbackDialog(type) {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      disableClose: true,
      data: {
        type
      }
    });
  }

}
