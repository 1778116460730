import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthDataService } from '../../../core/services/data/auth.data.service';
import { SnackbarService } from '../../../core/services/helpers/snackbar.service';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  public loginForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authDataService: AuthDataService,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<LoginDialogComponent>
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.authDataService.loginUser(this.loginForm.value).pipe(
      catchError(e => {
        if (e.statusCode === 401) {
          this.snackbarService.showErrorMessage('Invalid username or password');
        } else {
          this.snackbarService.showErrorMessage(e.message);
        }
        return throwError(e);
      })
    )
      .subscribe(() => {
        this.dialogRef.close();
        this.router.navigate(['/']);
      });
  }
}
