<div class="app__container" [class.on-top]="isMobile">
  <mat-toolbar class="header" fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayoutAlign="flex-start center">
      <button mat-icon-button class="menu__button" (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <h1 class="app__name">PCount</h1>
    </div>
    <div fxLayoutAlign="flex-start center">
      <fa-icon class="icon__item link"
               [icon]="['far', 'envelope']"
               [matMenuTriggerFor]="feedbackMenu"
      ></fa-icon>
      <mat-menu #feedbackMenu="matMenu">
        <button mat-menu-item (click)="openFeedbackDialog('bug')">
          Report a problem
        </button>
        <button mat-menu-item (click)="openFeedbackDialog('feedback')">
          Send feedback
        </button>
        <button mat-menu-item (click)="openFeedbackDialog('feature')">
          Request a feature
        </button>
      </mat-menu>
      <div [matBadge]="newNotifications" matBadgePosition="before" [matBadgeHidden]="newNotifications > 0 ? false : true">
        <fa-icon class="icon__item link"
                 [icon]="['far', 'bell']"
                 (click)="openNotifications()"
        >
        </fa-icon>
      </div>
      <fa-icon class="icon__item link"
               [icon]="['fas', 'cog']"
               [matMenuTriggerFor]="profileMenu"
      ></fa-icon>
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item routerLink="/system/profile">
          <fa-icon class="icon__item" [icon]="['fas', 'user']"></fa-icon>
          Profile
        </button>
        <button mat-menu-item routerLink="#" (click)="logout()">
          <fa-icon class="icon__item" [icon]="['fas', 'sign-out-alt']"></fa-icon>
          Logout
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav-container #snavc class="sidenav__container"
                         [style.marginTop.px]="isMobile ? 56 : 0">
    <mat-sidenav #snav mode="over">
      <mat-nav-list>
        <app-sidenav-item *ngFor="let item of navbarItems" [item]="item" [depth]="1" [sidenav]="snavc"></app-sidenav-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="component__container">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
