import {Component, OnInit, Input, AfterViewInit, OnChanges, ViewChild, ElementRef, OnDestroy, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import * as c3 from 'c3';
import { ChartConfiguration } from 'c3';

@Component({
  selector: 'app-c3-bar-chart',
  templateUrl: './c3-bar-chart.component.html',
  styleUrls: ['./c3-bar-chart.component.scss']
})
export class C3BarChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('someDiv') someDiv: ElementRef;
  @HostListener('window:resize', ['$event'])
  public responsiveCheck(event) {
    this.onResize(event.target.innerWidth);
  }

  @Input() chartData;
  @Input() chartDataColumns: string[] = [];
  @Input() chartDataCategories: string[] = [];
  @Input() groupedData: boolean;
  @Input() customGroupOrder: string[];
  @Input() showLabels = false;
  @Input() chartHeight = 450;
  @Input() chartWidth;
  @Input() xLabel = '';
  @Input() yLabel = '';
  @Input() yTickDataType: string;
  @Input() tooltipFormat: string;
  @Input() multiline = false;
  @Input() colorPattern: string[] = [];
  @Input() id: string;
  @Input() zoomable: boolean;
  @Input() rotated = false;
  @Input() shouldRedirect = false;
  @Input() simpleTicks = false;
  @Input() defaultStepSize = 6;
  @Input() responsiveMinWidth = 800;
  @Input() showGrid = false;

  constructor(
    private router: Router,
    private currency: CurrencyPipe,
    private decimal: DecimalPipe,
  ) {}

  chart: any;
  private init = false;
  private chartConfig: ChartConfiguration;

  ngOnInit() {
    this.chartConfig = {
      bindto: `#${this.id}`,
      onrendered: () => {
        if (!this.simpleTicks) {
          this.initiateNumberOfTicks();
        }
      },
      onresize: () => {
        if (!this.simpleTicks) {
          this.initiateNumberOfTicks();
        }
      },
      size: {
        height: this.chartHeight
      },
      zoom: {
        enabled: true,
        rescale: false,
        onzoom: (domain) => {
          this.updateNumberOfTicks(domain);
        }
      },
      padding: {
        left: 50,
        right: 20
      },
      interaction: { enabled: true },
      tooltip: {
        show: true,
        format: {
          value: (value: number, ratio, id, index) => {
            if (value < 0) {
              return `${Math.abs(value)}`;
            } else {
              return value;
            }
          }
        }
      },
      transition: { duration: 0 },
      data: {
        columns: this.chartData,
        type: 'bar',
        labels: this.showLabels,
        empty: { label: { text: 'Please select a location !' } }
      },
      bar: {
        width: {
          ratio: 0.5
        }
      },
      axis: {
        rotated: this.rotated,
        x: {
          label: {
            text: this.xLabel,
            position: 'outer-right'
          },
          type: 'category',
          categories: this.chartDataCategories,
          tick: {
            multiline: true
          }
        },
        y: {
          inner: false,
          label: {
            text: this.yLabel,
            position: 'outer-middle'
          },
          tick: {
            format: (d) => {
              return d % 1 === 0 ? d.toString() : '';
            }
          },
          padding: {
            left: 0,
            top: 10,
            right: 0,
            bottom: 0
          },
        }
      },
      legend: {
        show: true,
        item: {
          // disable click on legend
          onclick: () => {
            return false;
          }
        }
      },
      grid: {
        y: {
          show: this.showGrid
        }
      },
      color: {
        pattern: this.colorPattern
      }
    } as ChartConfiguration;

    if (this.yTickDataType) {
      this.formatYTicks();
    }

    if (this.tooltipFormat) {
      this.formatTooltip();
    }

    if (this.groupedData) {
      this.enableStackedData();
    }

    if (this.multiline != null) {
      this.allowTickDisplayOnMultipleLines();
    }

    if (this.zoomable === false) {
      this.disableZoom();
    }

    if (this.chartWidth) {
      this.setChartWidth();
    }
  }

  ngOnChanges(changes): any {
    // render c3 object
    if (this.init) {
      this.chartConfig.data.columns = this.chartData;
      this.chartConfig.axis.x.categories = this.chartDataCategories;
      this.chartConfig.grid.y.show = !!this.chartData.length;
      this.render();
      this.onResize(window.innerWidth);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.render());
    this.init = true;
  }

  /**
   * Generate the chart
   */
  render() {
    this.chart = c3.generate(this.chartConfig);
  }

  /**
   * Format the tooltip data type.
   */
  public formatTooltip() {
    this.chartConfig.tooltip = {
      format: {
        value: (value: number, ratio, id, index) => {
          switch (this.tooltipFormat) {
            case 'percentage': {
              return `${value}%`;
            }
            case 'currency': {
              return `${this.currency.transform(value, 'USD', 'symbol', '1.0-0')}`;
            }
            default:
              return this.decimal.transform(value);
          }
        }
      }
    };
  }

  /**
   * Format the Y axe tick data type.
   */
  public formatYTicks() {
    this.chartConfig.axis.y.tick.format = ((d) => {
      switch (this.yTickDataType) {
        case 'percentage': {
          return d + '%';
        }
        case 'currency': {
          return `${this.currency.transform(d, 'USD', 'symbol', '1.0-0')}`;
        }
        default:
          return this.decimal.transform(d);
      }
    });
  }

  /**
   * Enable stacked bar chart.
   */
  public enableStackedData() {
    const group = [];
    this.chartData.forEach(dataSet => group.push(dataSet[0]));
    this.chartConfig.data.groups = [group];
    if (this.customGroupOrder) {
      this.chartConfig.data.order = (a, b) => {
        const index1 = this.customGroupOrder.findIndex((option) => option === a.id);
        const index2 = this.customGroupOrder.findIndex((option) => option === b.id);

        if (index1 < index2) {
          return -1;
        } else {
          return 1;
        }
      };
    } else {
      this.chartConfig.data.order = 'asc';
    }
  }

  public setChartWidth() {
    this.chartConfig.size.width = this.chartWidth;
  }

  /**
   * Allow X axe ticks to be displayed on multiple lines.
   */
  public allowTickDisplayOnMultipleLines() {
    this.chartConfig.axis.x.tick.multiline = this.multiline;
  }

  /**
   * Disable zoom on chart.
   */
  public disableZoom() {
    this.chartConfig.zoom.enabled = false;
  }

  /**
   * Configure the number of ticks to be displayed at graph initialization
   */
  initiateNumberOfTicks() {
    if (document.getElementById(this.id)) {
      const ticks: HTMLCollection = document.getElementById(this.id).getElementsByClassName('c3-axis-x')[0].getElementsByClassName('tick');
      const step = Math.ceil(ticks.length / this.defaultStepSize);
      Array.from(ticks).forEach((tick: HTMLElement, index: number) => {
        if (index % step !== 0) {
          tick.classList.add('display--none');
        }
      });
    }
  }

  /**
   * Calculate the number of ticks while zooming
   */
  updateNumberOfTicks(domain) {
    // display the ticks based on the domain zoomed
    if (domain) {
      const step = Math.ceil((domain[1] - domain[0]) / this.defaultStepSize);
      const ticks: HTMLCollection = document.getElementById(this.id).getElementsByClassName('c3-axis-x')[0].getElementsByClassName('tick');
      Array.from(ticks).forEach((tick: HTMLElement, index: number) => {
        if ( (domain[0] > index || index > domain[1]) || (index % step !== 0)) {
          tick.classList.add('display--none');
        } else {
          tick.classList.remove('display--none');
        }
      });
    }
  }

  onResize(width) {
    if (this.responsiveMinWidth) {
      if (width < 960) {
        this.chart.resize({width: this.responsiveMinWidth});
      } else {
        this.chart.resize();
      }
    }
  }

  ngOnDestroy() {
    this.chart.destroy();
  }
}
