import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { StorageService } from '../helpers/storage.service';
import { SnackbarService } from '../helpers/snackbar.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private snackabarService: SnackbarService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap((response: HttpResponse<any>) => {
          if (response.status) {
            const resetToken = response.headers.get('Refreshed-Access-Token');
            if (resetToken) {
              this.storageService.set('token', resetToken);
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.storageService.remove('token');
            this.storageService.remove('user');
            this.router.navigate(['/auth']);
            this.snackabarService.showErrorMessage('You are currently not logged in or your session has expired. Please login to access the application.');
          }

          // return throwError(_.get(error, 'error.error', error.error));
          return throwError(error.error);
        })
      );
  }
}
