<div class="card__container margin" [ngClass]="{'overflow-x': overflow.x, 'overflow-y': overflow.y}" fxLayout="column">
  <div *ngIf="name" class="card__title" fxLayout="row" fxLayoutAlign="space-between">
    <span>{{name}}</span>
    <button *ngIf="data" mat-raised-button color="primary" (click)="exportCSV()">Export CSV</button>
    <div class="checkbox__container" *ngIf="showWorkingHours || showExcludeChildren" fxLayout="row" fxLayoutGap="10px">
      <mat-checkbox *ngIf="showWorkingHours" [formControl]="intervalFormControl">View only working hours</mat-checkbox>
      <mat-checkbox *ngIf="showExcludeChildren" [formControl]="excludeChildrenFormControl" [disabled]="disableExcludeChildren" [matTooltip]="disableExcludeChildren ? 'Selected entities contain webcams that do nost support children counting.' : ''">Exclude children</mat-checkbox>
    </div>
  </div>
  <div fxLayout="row" class="card__body">
    <ng-content></ng-content>
  </div>
</div>
