import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { AuthenticatedComponent } from './components/authenticated/authenticated.component';
import { SidenavItemComponent } from './components/sidenav-item/sidenav-item.component';
import { RequestInterceptor } from './services/interceptors/request.interceptor';
import { AuthDataService } from './services/data/auth.data.service';
import { WebcamsDataService } from './services/data/webcams.data.service';
import { SnackbarService } from './services/helpers/snackbar.service';
import { StorageService } from './services/helpers/storage.service';
import { AuthenticationGuard } from './services/guards/authentication.guard';
import { UsersDataService } from './services/data/users.data.service';
import { AuthorizationGuard } from './services/guards/authorization.guard';
import { EntitiesDataService } from './services/data/entities.data.service';
import { ScheduledReportsDataService } from './services/data/scheduled-reports.data.service';
import { ReportsAuditDataService } from './services/data/reports-audit.data.service';
import { WeatherDataService } from './services/data/weather.data.service';
import { DialogService } from './services/helpers/dialog.service';
import { ProductsDataService } from './services/data/products.data.service';
import { EventsDataService } from './services/data/events.data.service';
import { ResponseInterceptor } from './services/interceptors/response.interceptor';
import { NotificationsDataService } from './services/data/notifications.data.service';
import { NotificationService } from './services/helpers/notification.service';
import { PublicComponent } from './components/public/public.component';
import { ApiClientsDataService } from './services/data/api-clients.data.service';

@NgModule({
  declarations: [
    AuthenticatedComponent,
    SidenavItemComponent,
    PublicComponent
  ],
  imports: [
    SharedModule,
    HttpClientModule
  ],
  exports: [
    AuthenticatedComponent,
    SidenavItemComponent
  ],
  providers: [
    SnackbarService,
    StorageService,
    AuthDataService,
    WebcamsDataService,
    UsersDataService,
    AuthenticationGuard,
    AuthorizationGuard,
    EntitiesDataService,
    ScheduledReportsDataService,
    ReportsAuditDataService,
    WeatherDataService,
    DialogService,
    ProductsDataService,
    EventsDataService,
    NotificationsDataService,
    NotificationService,
    ApiClientsDataService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
  ]
})

export class CoreModule {}
