import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notification-details-dialog',
  templateUrl: './notification-details-dialog.component.html',
  styleUrls: ['./notification-details-dialog.component.scss']
})
export class NotificationDetailsDialogComponent implements OnInit {

  public notificationForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NotificationDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('50%', '50%');
    this.notificationForm = this.formBuilder.group({
      description: [this.data.notification.description as string],
    });
  }

}
