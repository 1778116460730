import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NotificationsDataService } from '../../../core/services/data/notifications.data.service'
import { StorageService } from '../../../core/services/helpers/storage.service';

import { NotificationDetailsDialogComponent } from '../notification-details-dialog/notification-details-dialog.component';
import { NotificationService } from '../../../core/services/helpers/notification.service';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {

  public userId: string;
  public newNotifications: number;

  constructor(
    private notificationsDataService: NotificationsDataService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    public dialogRef: MatDialogRef<NotificationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('50%', '50%');
    this.userId = this.storageService.get('user').id;
    this.newNotifications = this.data.newNotifications;
  }

  openNotificationDetails(notification) {
    this.notificationsDataService.markNotificationAsRead(this.userId, notification._id).subscribe((response) => {
      notification.read = true;

      this.notificationService.setNewNotificationCount(--this.data.newNotifications);
    });

    this.dialog.open(NotificationDetailsDialogComponent, {
      data: {
        notification,
        roles: this.data.roles
      }
    });
  }

}
