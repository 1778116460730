import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import * as _ from 'lodash';

@Injectable()
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  private defaultDurationInSeconds = 5;

  /**
   * Show success message
   * @param message - message
   * @param action - action
   */
  showSuccessMessage(message: string, action: string = 'Close') {
    const config = new MatSnackBarConfig();
    config.duration = this.defaultDurationInSeconds * 1000;
    config.panelClass = ['snack_bar', 'snack_bar-success'];
    this.snackBar.open(message, action, config);
  }

  /**
   * Show error message
   * @param message - message
   * @param action - action
   */
  showErrorMessage(message: string, action: string = 'Close') {
    const config = new MatSnackBarConfig();
    config.duration = this.defaultDurationInSeconds * 1000;
    config.panelClass = ['snack_bar', 'snack_bar-error'];

    this.snackBar.open(message, action, config);
  }

  /**
   * Show multiple errors received from backend
   * @param errorResponse - error from backend
   */
  showBackendErrorsMessage(errorResponse) {
    let errorMessage = '';
    _.forEach(errorResponse.error, (err) => {
      errorMessage += err.message + '\n';
    });
    this.showErrorMessage(errorMessage);
  }

}
