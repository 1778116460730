<!--<div class="header">-->
<!--  <div class="header__logo">-->
<!--    <img src="assets/images/avatar.jpeg" (click)="redirectTo('/auth/')"/>-->
<!--  </div>-->
<!--  <div class="header__login">-->
<!--    <form [formGroup]="loginForm" fxLayout="row" fxLayoutAlign="center flex-start" fxLayoutGap="10px" (ngSubmit)="onSubmit()" *ngIf="!isMobile">-->
<!--      <mat-form-field appearance="outline">-->
<!--        <mat-label>Email</mat-label>-->
<!--        <input matInput formControlName="email" />-->
<!--        <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>-->
<!--        <mat-error *ngIf="hasError('email', 'email')">Invalid Email</mat-error>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field appearance="outline">-->
<!--        <mat-label>Password</mat-label>-->
<!--        <input matInput placeholder="Password" formControlName="password" type="password"/>-->
<!--        <mat-error *ngIf="hasError('password', 'required')">Password is required</mat-error>-->
<!--      </mat-form-field>-->
<!--      <div fxLayout="column" fxLayoutAlign="flex-start flex-end" class="login-button__container">-->
<!--        <button mat-stroked-button color="primary" type="submit" class="submit__button">-->
<!--          Login-->
<!--        </button>-->
<!--        <span routerLink="/auth/forgot-password">Forgot password?</span>-->
<!--      </div>-->
<!--    </form>-->

<!--    <button mat-icon-button-->
<!--            [matMenuTriggerFor]="menu"-->
<!--            aria-label="Example icon-button with a menu"-->
<!--            *ngIf="isMobile"-->
<!--    >-->
<!--      <fa-icon class="icon__item link"-->
<!--               [icon]="['fas', 'bars']"-->
<!--               size="2x"-->
<!--      ></fa-icon>-->
<!--    </button>-->
<!--    <mat-menu #menu="matMenu">-->
<!--      <button mat-menu-item (click)="openLoginDialog()">-->
<!--        <fa-icon class="icon__item link"-->
<!--                 [icon]="['fas', 'sign-in-alt']"-->
<!--        ></fa-icon>-->
<!--        <span class="public-menu__option">Login</span>-->
<!--      </button>-->
<!--    </mat-menu>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="navigator">-->
<!--  <div class="nav-item">-->
<!--    <ng-container *ngFor="let sidebarItem of sidebarItems; let i = index">-->
<!--      <a [href]="sidebarItem.link" aria-current="page" class="nav-link-text w&#45;&#45;current">{{sidebarItem.title}}</a>-->
<!--      <div *ngIf="i < sidebarItems.length - 1" class="line-separator"></div>-->
<!--    </ng-container>-->
<!--  </div>-->
<!--</div>-->
<!--<div>-->
<!--  <div class="public__container">-->
    <router-outlet></router-outlet>
<!--  </div>-->
<!--</div>-->
