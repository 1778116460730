import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscriber } from 'rxjs';
import { LoadingDialogModel, LoadingDialogComponent } from 'src/app/shared/components/loading-dialog/loading-dialog.component';

@Injectable()
export class DialogService {
  /**
   * Constructor
   */
  constructor(
    private dialog: MatDialog,
  ) {}


  /**
   * Display custom dialog
   * @param componentOrTemplateRef
   * @param config
   */
  showCustomDialog(
    componentOrTemplateRef: any,
    config: any
  ): Observable<any> {
    // open dialog
    return this.dialog.open(
      componentOrTemplateRef,
      config
    ).afterClosed();
  }


  /**
   * Display loading dialog
   */
  showLoadingDialog(): LoadingDialogModel {
    // display dialog
    const dialog: MatDialogRef<LoadingDialogComponent> = this.dialog.open(
      LoadingDialogComponent,
      LoadingDialogComponent.DEFAULT_CONFIG
    );

    // finished creating dialog
    return new LoadingDialogModel(
      Subscriber.create(() => {
        // close dialog
        dialog.close();
      })
    );
  }

}
