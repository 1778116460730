import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../core/services/helpers/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-top-entities-dialog',
  templateUrl: './top-entities-dialog.component.html',
  styleUrls: ['./top-entities-dialog.component.scss']
})
export class TopEntitiesDialogComponent implements OnInit {

  public user;
  public topForm: FormGroup;
  public orientations = [
    {
      value: 'top',
      label: 'Top'
    },
    {
      value: 'bottom',
      label: 'Bottom'
    }
  ];

  constructor(
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TopEntitiesDialogComponent>
  ) { }

  ngOnInit(): void {
    this.user = this.storageService.get('user');
    this.topForm = this.formBuilder.group({
      type: ['top', [Validators.required]],
      amount: [5, [Validators.required]]
    });
  }

  onSubmit() {
    this.dialogRef.close(this.topForm.value);
  }

}
