import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityEntity } from '../../entities/entity.entity';

@Injectable()
export class WeatherDataService {

  constructor(
    private http: HttpClient
  ) { }

  public getDayForecasts(filter) {
    return this.http.get('weather/day-forecasts', {
      params: {
        filter
      }}
    );
  }

  public getHourForecasts(filter) {
    return this.http.get('weather/hour-forecasts', {
      params: {
        filter
      }
    });
  }

  public getHourForecastsByEntity(filter) {
    return this.http.get('weather/hour-forecasts-by-entity', {
      params: {
        filter
      }
    });
  }

  public getDayForecastsByEntity(filter) {
    return this.http.get('weather/day-forecasts-by-entity', {
      params: {
        filter
      }
    });
  }

  public instantEntityForecast(entity: EntityEntity) {
    return this.http.post(`weather/instant-forecast`, entity);
  }
}
