import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChartPie, faHome, faCog, faUser, faSignInAlt, faSignOutAlt, faAngleRight, faInfoCircle, faBars } from '@fortawesome/free-solid-svg-icons';
import { faBell, faEnvelope, faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { C3BarChartComponent } from './components/c3-bar-chart/c3-bar-chart.component';
import { CardComponent } from './components/card/card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { HourlyForecastDetailsDialogComponent } from './components/hourly-forecast-details-dialog/hourly-forecast-details-dialog.component';
import { DailyForecastDetailsDialogComponent } from './components/daily-forecast-details-dialog/daily-forecast-details-dialog.component';
import { TopEntitiesDialogComponent } from './components/top-entities-dialog/top-entities-dialog.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { NotificationsDialogComponent } from './components/notifications-dialog/notifications-dialog.component';
import { NotificationDetailsDialogComponent } from './components/notification-details-dialog/notification-details-dialog.component';
import { FeedbackDialogComponent } from './components/feedback-dialog/feedback-dialog.component';
import { EntitySelectorDialogComponent } from './components/entity-selector-dialog/entity-selector-dialog.component';
import { GridItemComponent } from './components/grid-item/grid-item.component';
import { HoverClassDirective } from './directives/hover-class.directive';
import { FeaturePageComponent } from './components/feature-page/feature-page.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY, HH:mm'
  },
  display: {
    dateInput: 'DD/MM/YYYY, HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    FontAwesomeModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    NgSelectModule
  ],
  declarations: [
    C3BarChartComponent,
    CardComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    HourlyForecastDetailsDialogComponent,
    DailyForecastDetailsDialogComponent,
    TopEntitiesDialogComponent,
    LoadingDialogComponent,
    NotificationsDialogComponent,
    NotificationDetailsDialogComponent,
    FeedbackDialogComponent,
    EntitySelectorDialogComponent,
    GridItemComponent,
    HoverClassDirective,
    FeaturePageComponent,
    LoginDialogComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    FontAwesomeModule,
    C3BarChartComponent,
    CardComponent,
    LoadingComponent,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    ConfirmDialogComponent,
    HourlyForecastDetailsDialogComponent,
    DailyForecastDetailsDialogComponent,
    TopEntitiesDialogComponent,
    LoadingDialogComponent,
    NgSelectModule,
    NotificationsDialogComponent,
    NotificationDetailsDialogComponent,
    FeedbackDialogComponent,
    EntitySelectorDialogComponent,
    GridItemComponent,
    HoverClassDirective,
    FeaturePageComponent,
    LoginDialogComponent
  ],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})

export class SharedModule {
  constructor(
    private library: FaIconLibrary
  ) {
    this.library.addIcons(faHome, faChartPie, faBell, faCog, faUser, faSignInAlt, faSignOutAlt, faAngleRight, faEnvelope, faWindowClose, faInfoCircle, faBars);

  }
}
