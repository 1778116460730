import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReportsAuditDataService {
  constructor(
    private http: HttpClient
  ) {}

  public find(query = {filter: JSON.stringify({})}) {
    return this.http.get('reports-audit', {params: query});
  }
}
