<div class="form__dialog">
  <div fxLayout="row" fxLayoutAlign="flex-end center">
    <fa-icon
      class="icon__item"
      [icon]="['far', 'window-close']"
      matDialogClose
    ></fa-icon>
  </div>

  <div class="dialog__content">
    <div
      fxLayout="row"
      class="title__container"
      fxLayoutAlign="space-between flex-end"
    >
      <div class="dialog__title">Notification Details</div>
    </div>

    <form mat-form [formGroup]="notificationForm">
      <mat-form-field class="form__fields" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput
                  formControlName="description"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="10"
                  cdkAutosizeMaxRows="20"
                  readonly
        ></textarea>
        <mat-error *ngIf="notificationForm.controls.description.hasError('required')">
          This field is required.
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</div>
