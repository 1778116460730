import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../helpers/storage.service';
import { map } from 'rxjs/operators';
import  {Observable, of } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthDataService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  public loginUser(user): Observable<any> {
    return this.http.post('auth/login', user)
      .pipe(
        map((authenticatedUser: any) => {
          if (authenticatedUser && authenticatedUser.accessToken) {
            const token = authenticatedUser.accessToken;
            const decodedToken = jwt_decode(token);
            this.storageService.set('token', token);
            this.storageService.set('user', decodedToken);
          }

          return authenticatedUser;
        })
      );
  }
}
