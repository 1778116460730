import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotificationService {

  public newNotificationCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() {
  }

  public setNewNotificationCount(count) {
    this.newNotificationCount.next(count);
  }

  public getNewNotificationCount() {
    return this.newNotificationCount.asObservable();
  }
}
