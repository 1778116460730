import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedComponent } from './core/components/authenticated/authenticated.component';
import { AuthenticationGuard } from './core/services/guards/authentication.guard';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '',
    component: AuthenticatedComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'views'
      },
      {
        path: 'views',
        loadChildren: () => import('./modules/views/views.module').then(m => m.ViewsModule)
      },
      {
        path: 'system',
        loadChildren: () => import('./modules/system/system.module').then(m => m.SystemModule)
      }
    ],
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'display',
    loadChildren: () => import('./modules/display/display.module').then(m => m.DisplayModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'pdf',
    loadChildren: () => import('./modules/pdf/pdf.module').then(m => m.PdfModule)
  },
  {
    path: '**',
    redirectTo: 'views'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
