<div fxLayout="column" fxFill fxLayoutAlign="center center">
  <div class="login__wrapper">
    <div class="login__header">
      Welcome to PCount
    </div>
    <div class="login__content">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div fxLayout="row" class="input__row">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
            <mat-error *ngIf="hasError('email', 'email')">Invalid Email</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" class="input__row">
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
              <input matInput placeholder="Password" formControlName="password" type="password"/>
              <mat-error *ngIf="hasError('password', 'required')">Password is required</mat-error>
          </mat-form-field>
        </div>
        <div class="actions">
          <button mat-stroked-button color="primary" type="submit" class="submit__button">
            Login
          </button>
        </div>
        <div class="links" fxLayout="row" fxLayoutAlign="flex-end">
          <a routerLink="/auth/forgot-password">
            Forgot Password
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
