<div class="portfolio-warp">
  <div class="work-intro-warp">
    <div class="work-intro-head">
      <h1 class="portfolio-title">
        {{config.title}}
      </h1>
    </div>
    <div class="work-intro-sub">
      <h2 class="portfolio-subtitle">
        Nunc mattis libero et fringilla finibus. Etiam ac risus nec nulla ullamcorper pulvinar. Proin commodo a
        quam eu sodales. Nulla facilisi.
      </h2>
    </div>
    <div class="work-intro-service">
      <div *ngFor="let tag of config.tags" class="label-text">
        {{tag}}
      </div>
    </div>
  </div>
  <div *ngFor="let image of config.images" [ngClass]="{'folio-full-grid': image.type === 'full', 'folio-grid': image.type === 'half'}">
    <ng-container *ngIf="image.type === 'full'">
      <img [src]="image.sourcePath"
           class="img-100">
    </ng-container>
    <ng-container *ngIf="image.type === 'half'">
      <div class="folio-grid-item">
        <img [src]="image.sourcePath[0]" class="img-100">
      </div>
      <div class="folio-grid-item">
        <img [src]="image.sourcePath[1]" class="img-100">
      </div>
    </ng-container>
  </div>
  <div class="w-layout-grid port-info">
    <h2>Feature Description</h2>
    <div class="rte-portfolio w-richtext">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent posuere pulvinar velit, sit amet sodales metus gravida a.
        Duis molestie cursus lacus vel efficitur. Vivamus tincidunt augue eu libero euismod luctus. Duis et sodales ligula.
      </p>
      <p>Sed porttitor sapien ex, quis euismod orci pharetra sed. Interdum et malesuada fames ac ante ipsum primis in faucibus.
        Vivamus ac porttitor ex.
      </p>
      <p>Praesent ultricies ornare viverra. Fusce scelerisque mauris et tortor placerat aliquet. Curabitur ullamcorper eros eget lacus facilisis,
        sit amet ultricies urna dapibus. Aenean pellentesque, urna eget mattis lacinia, enim leo vulputate turpis, quis molestie sem leo at erat.<br>
      </p>
    </div>
  </div>
</div>
