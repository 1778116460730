import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiClientsDataService {
  constructor(
    private http: HttpClient
  ) { }

  // Returns a list of API clients
  public getApiClients(query = {filter: '{}'}) {
    return this.http.get('api-clients', {params: query});
  }

  // Create a new API client
  public createApiClient(payload) {
    return this.http.post('api-clients', payload);
  }

  // Update an existing API client
  public updateApiClient(apiClientId, payload) {
    return this.http.put(`api-clients/${apiClientId}`, payload);
  }

  // Delete an API client
  public deleteApiClient(apiClientId) {
    return this.http.delete(`api-clients/${apiClientId}`);
  }
}
