<div class="table__container">
  <table cellspacing="0">
    <thead>
      <th>Category</th>
      <th>Value</th>
    </thead>

    <tbody>
      <tr>
        <td>Temperature</td>
        <td>{{data.temperature.min}} &#8451; - {{data.temperature.max}} &#8451;</td>
      </tr>
      <tr>
        <td>Real Feel Temperature</td>
        <td>{{data.realFeelTemperature.min}} &#8451; - {{data.realFeelTemperature.max}} &#8451;</td>
      </tr>

      <tr>
        <th colspan="2" class="sub-header">
          Day
        </th>
      </tr>
      <tr>
        <td>Sky</td>
        <td>{{data.day.iconPhrase}}</td>
      </tr>
      <tr>
        <td>Precipitations</td>
        <td>{{data.day.hasPrecipitation ? 'Yes' : 'None'}}</td>
      </tr>
      <tr *ngIf="data.day.hasPrecipitation">
        <td>Precipitation Type</td>
        <td>{{data.day.precipitationType}}</td>
      </tr>
      <tr>
        <td>Precipitation Probability</td>
        <td>{{data.day.precipitationProbability}} %</td>
      </tr>
      <tr>
        <td>Wind Speed</td>
        <td>{{data.day.windSpeed}} km/h</td>
      </tr>

      <tr>
        <th colspan="2" class="sub-header">
          Night
        </th>
      </tr>
      <tr>
        <td>Sky</td>
        <td>{{data.night.iconPhrase}}</td>
      </tr>
      <tr>
        <td>Precipitations</td>
        <td>{{data.night.hasPrecipitation ? 'Yes' : 'None'}}</td>
      </tr>
      <tr *ngIf="data.night.hasPrecipitation">
        <td>Precipitation Type</td>
        <td>{{data.night.precipitationType}}</td>
      </tr>
      <tr>
        <td>Precipitation Probability</td>
        <td>{{data.night.precipitationProbability}} %</td>
      </tr>
      <tr>
        <td>Wind Speed</td>
        <td>{{data.night.windSpeed}} km/h</td>
      </tr>
    </tbody>
  </table>
</div>
