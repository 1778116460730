import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class EntitiesDataService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Returns an entity.
   * @param entityId
   * @returns {Observable<Object>}
   */
  public getEntity(entityId) {
    return this.http.get(`entities/${entityId}`);
  }

  /**
   * Returns all entities.
   * @returns {Observable<Object>}
   */
  public getEntities(query: any = {filter: JSON.stringify({})}, method = 'GET'): Observable<any[]> {
    if (method === 'GET') {
      return this.http.get<any[]>('entities', {params: query});
    } else {
      return this.http.post<any[]>('entities/get-entities', query);
    }
  }

  public getEntityData(payload): Observable<any> {
    return this.http.post('entities/data', payload);
  }

  /**
   * Create an entity.
   * @param payload
   * @returns {Observable<Object>}
   */
  public createEntity(payload) {
    return this.http.post('entities', payload);
  }

  /**
   * Update an entity.
   * @param entityId
   * @param payload
   * @returns {Observable<Object>}
   */
  public updateEntity(entityId, payload) {
    return this.http.put(`entities/${entityId}`, payload);
  }

  /**
   * Returns the number of people in an entity at the current time.
   * @param query
   * @returns {Observable<Object>}
   */
  public getLiveCount(query, skip = null, limit = null) {
    return this.http.get('entities/live', {
      params: {
        filter: query,
        skip,
        limit
      }
    });
  }

  public getEntityAccuWeatherLocationKey(lat, lng) {
    return this.http.get('http://dataservice.accuweather.com/locations/v1/cities/geoposition/search',
      {
        params: {
          apikey: environment.accuWeatherApiKey,
          q: `${lat},${lng}`,
          language: 'en-us',
          details: 'false',
          toplevel: 'false'
        }
      });
  }

  public getTopEntities(payload) {
    return this.http.post('entities/data/top', payload);
  }

  public getWebcamTypesMap(webcamIds) {
    return this.http.post('entities/getWebcamsTypeMap', {webcamIds});
  }
}
