<table cellspacing="0">
  <thead>
    <th>Category</th>
    <th>Value</th>
  </thead>
  <tbody>
    <tr>
      <td>Sky</td>
      <td>{{data.iconPhrase}}</td>
    </tr>
    <tr>
      <td>Precipitations</td>
      <td>{{data.hasPrecipitation ? 'Yes' : 'None'}}</td>
    </tr>
    <tr *ngIf="data.hasPrecipitation">
      <td>Precipitation Type</td>
      <td>{{data.precipitationType}}</td>
    </tr>
    <tr>
      <td>Precipitation Probability</td>
      <td>{{data.precipitationProbability}} %</td>
    </tr>
    <tr>
      <td>Temperature</td>
      <td>{{data.temperature}} &#8451;</td>
    </tr>
    <tr>
      <td>Real Feel Temperature</td>
      <td>{{data.realFeelTemperature}} &#8451;</td>
    </tr>
    <tr>
      <td>Wind Speed</td>
      <td>{{data.windSpeed}} km/h</td>
    </tr>
  </tbody>
</table>
