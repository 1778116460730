import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../helpers/storage.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = request.clone({
      url: this.normalizeUrl(request.url),
      setHeaders: this.getHeaders(request.url)
    });

    return next.handle(clonedRequest);
  }

  private normalizeUrl(url: string): string {
    if (
      url.indexOf('http://') === 0 ||
      url.indexOf('https://') === 0
    ) {
      return url;
    } else {
      return `${environment.apiUrl}/${url}`;
    }
  }

  private getHeaders(url) {
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
      const headers = { };

      // set Auth Header if existing
      const token = this.storageService.get('token');
      if (token) {
        // tslint:disable-next-line: no-string-literal
        headers['Authorization'] = `Bearer ${token}`;
      }

      return headers;
    } else {
      return {};
    }
  }
}
