import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ScheduledReportsDataService {

  constructor(
    private http: HttpClient,
  ) { }

  public find(query = {filter: JSON.stringify({})}) {
    return this.http.get('scheduled-reports', {params: query});
  }

  public create(payload) {
    return this.http.post('scheduled-reports', payload);
  }

  public update(reportId, payload) {
    return this.http.put(`scheduled-reports/${reportId}`, payload);
  }

  public deleteOne(reportId) {
    return this.http.delete(`scheduled-reports/${reportId}`);
  }

  public getReportData(reportId) {
    return this.http.get(`scheduled-reports/${reportId}/csv`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public getReportChartData(reportId) {
    return this.http.get(`scheduled-reports/${reportId}/chart-data`);
  }

  public sendNow(scheduledReport) {
    return this.http.post('scheduled-reports/send-now', scheduledReport);
  }
}
