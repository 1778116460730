<div class="container">
  <div class="link" [routerLink]="config.link">
    <div class="port-warp">
      <div hoverClass="visible" class="portfolio-overlay">
        <div class="portfolio-overlay-warp">
          <div class="portfolio-overlay-title">
            <h2 class="overlay-title">{{config.title}}</h2>
          </div>
          <div class="portfolio-overlay-tag">
            <p class="label-text dark" *ngFor="let tag of config.tags">{{tag}}</p>
          </div>
          <div class="portfolio-overlay-body">
            <p class="port-overlay-p">{{config.body}}</p>
          </div>
          <div class="portfolio-overlay-cta">
            <p class="port-overlay-a">
              View functionality
            </p>
          </div>
        </div>
      </div>
      <img [src]="config.imgSrc"
           alt="Anility"
           data-w-id="48eafcd7-63c1-b6ac-fc31-90fcd6d90c60"
           loading="lazy"
           class="portfolio-cover"
           style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
           transform-style: preserve-3d;">
    </div>
  </div>
</div>
