export class Constants {
  static ROLES = {
    admin: 'Admin',
    supervisor: 'Supervisor',
    pSupervisor: 'Premium Supervisor',
    user: 'User',
    pUser: 'Premium User',
    all: ['Admin', 'Supervisor', 'Premium Supervisor', 'User', 'Premium User'],
    gtUser: ['Admin', 'Supervisor', 'Premium Supervisor'],
    premium: ['Admin', 'Premium Supervisor', 'Premium User']
  };

  static ENTITY_TYPES = [
    {
      label: 'Standard',
      value: 1
    },
    {
      label: 'Custom Live count',
      value: 2
    }
  ];

  static DATE_RANGES = [
    {
      label: 'Today',
      value: 1
    },
    {
      label: 'Yesterday',
      value: 2
    },
    {
      label: 'The last 7 days',
      value: 3
    },
    {
      label: 'This week',
      value: 4
    },
    {
      label: 'Last week',
      value: 5
    },
    {
      label: 'The last 2 weeks',
      value: 6
    },
    {
      label: 'This month',
      value: 7
    },
    {
      label: 'Last month',
      value: 8
    }
  ];

  static DATE_RANGES_MAP = {
    1: 'Today',
    2: 'Yesterday',
    3: 'The last 7 days',
    4: 'This week',
    5: 'Last week',
    6: 'The last 2 weeks',
    7: 'This month',
    8: 'Last month'
  };

  static DELIVERY_FREQUENCIES = [
    {
      label: 'Daily',
      value: 1
    },
    {
      label: 'Weekly',
      value: 2
    },
    {
      label: 'Bi-weekly',
      value: 3
    },
    {
      label: 'Monthly',
      value: 4
    }
  ];

  static DELIVERY_FREQUENCIES_MAP = {
    1: 'Daily',
    2: 'Weekly',
    3: 'Bi-weekly',
    4: 'Monthly'
  };

  static DAYS = [
    {
      label: 'Monday',
      value: 1
    },
    {
      label: 'Tuesday',
      value: 2
    },
    {
      label: 'Wednesday',
      value: 3
    },
    {
      label: 'Thursday',
      value: 4
    },
    {
      label: 'Friday',
      value: 5
    },
    {
      label: 'Saturday',
      value: 6
    },
    {
      label: 'Sunday',
      value: 7
    }
  ];

  static DAYS_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

  static RECURRENCE_ENDINGS = [
    {
      label: 'On',
      value: 'on'
    },
    {
      label: 'After',
      value: 'after'
    }
  ];
}
