import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../helpers/storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class UsersDataService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  public getUsers(query) {
    return this.http.get('users', {params: {filter: query}});
  }

  public getUser(userId) {
    return this.http.get(`users/${userId}`);
  }

  public createUser(payload) {
    return this.http.post('users', payload);
  }

  public updateUser(userId, payload) {
    return this.http.put(`users/${userId}`, payload);
  }

  public getRoles(query = '{}') {
    return this.http.get('roles', {params: {filter: query}});
  }

  public sendResetPasswordEmail(payload) {
    return this.http.post('users/send-reset-password-email', payload);
  }

  public resetPassword(payload) {
    return this.http.post('users/reset-password', payload);
  }

  public changePassword(userId, payload) {
    return this.http.post(`users/${userId}/change-password`, payload);
  }
}
