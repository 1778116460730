import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { NotificationEntity } from '../../entities/notification.entity';
import { NotificationModel } from '../../models/notification.model';
import { UserReadNotificationEntity } from '../../entities/user-read-notification.entity';

@Injectable()
export class NotificationsDataService {
  constructor(
    private http: HttpClient
  ) {}

  /**
   * Returns all notifications that pass a filter, if provided.
   * query
   */
  public getNotifications(query: any = {filter: '{}'}): Observable<NotificationEntity[]> {
    return this.http.get<NotificationEntity[]>('notifications', {params: query});
  }

  /**
   * Returns a notification by id.
   * notificationId
   */
  public getNotification(notificationId: string): Observable<NotificationEntity> {
    return this.http.get<NotificationEntity>(`notifications/${notificationId}`);
  }

  /**
   * Create a new notification.
   * payload
   */
  public createNotification(payload: NotificationModel): Observable<NotificationEntity> {
    return this.http.post<NotificationEntity>('notifications', payload);
  }

  /**
   * Update an existing notification.
   * notificationId
   * payload
   */
  public updateNotification(notificationId: string, payload: NotificationModel): Observable<NotificationEntity> {
    return this.http.put<NotificationEntity>(`notifications/${notificationId}`, payload);
  }

  /**
   * Delete an existing notification.
   * notificationId
   */
  public deleteNotification(notificationId: string) {
    return this.http.delete(`notifications/${notificationId}`);
  }

  /**
   * Get the ids of all the notifications read by mentioned user.
   * userId
   */
  public getUserReadNotifications(userId: string): Observable<UserReadNotificationEntity[]> {
    return this.http.get<UserReadNotificationEntity[]>(`notifications/readByUser/${userId}`);
  }

  /**
   * Mark a certain notification as read by a certain user
   * userId
   * notificationId
   */
  public markNotificationAsRead(userId: string, notificationId): Observable<UserReadNotificationEntity> {
    return this.http.post<UserReadNotificationEntity>('notifications/readByUser', {userId, notificationId});
  }

  public handleUserRequest(payload) {
    return this.http.post('notifications/requestByUser', payload);
  }
}
