import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WebcamsDataService } from '../../../core/services/data/webcams.data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, OnChanges {

  @Input() name: 'string';
  @Input() flexContent: boolean;
  @Input() overflow: any = {x: false, y: false};
  @Input() data: any;
  @Input() type: string;
  @Input() date: any;
  @Input() showWorkingHours: boolean;
  @Input() showExcludeChildren: boolean;
  @Input() disableExcludeChildren: boolean;
  @Output() workingHoursChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() excludeChildrenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public intervalFormControl: FormControl;
  public excludeChildrenFormControl: FormControl;

  constructor(
    private webcamsDataService: WebcamsDataService
  ) { }

  ngOnInit(): void {
    this.intervalFormControl = new FormControl(true);
    this.excludeChildrenFormControl = new FormControl(false);

    this.intervalFormControl.valueChanges.subscribe((value) => {
      this.workingHoursChanged.emit(value);
    });

    this.excludeChildrenFormControl.valueChanges.subscribe((value) => {
      this.excludeChildrenChanged.emit(value);
    });
  }

  ngOnChanges($changes) {
    if ($changes.showWorkingHours?.currentValue === true) {
      this.intervalFormControl.setValue(true);
    }
  }

  exportCSV() {
    let date;
    switch (this.type) {
      case 'Day':
        date = moment(this.date).format('YYYY-MM-DD');
        break;
      case 'Week':
        date = moment(this.date).format('YYYY-MM-DD');
        break;
      case 'Month':
        date = moment(this.date).format('YYYY-MM');
        break;
      case 'Year':
        date = moment(this.date).format('YYYY');
    }
    const payload = {
      type: this.type,
      date: date,
      data: this.data
    };
    this.webcamsDataService.downloadCsv(payload).subscribe(response => {
      const fileName = response.headers.get('Content-Disposition').split('=')[1];
      const blob: any = new Blob([response.body], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = (fileName);
      anchor.href = url;
      anchor.click();
    });
  }

}
