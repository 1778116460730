<div class="dialog__container" fxLayout="column" fxLayoutAlign="space-between">
  <div class="dialog__title">
    {{data.title}}
  </div>
  <div class="dialog__content">
    {{data.message}}
  </div>
  <div class="dialog__footer" fxLayoutAlign="space-between">
    <button mat-raised-button color="warn" [matDialogClose]="true">Confirm</button>
    <button mat-raised-button color="primary" [matDialogClose]="false">Cancel</button>
  </div>
</div>
