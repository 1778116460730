import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../helpers/storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class WebcamsDataService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  public getWebcams(query = {filter: JSON.stringify({})}): Observable<any> {
    return this.http.get('webcams', {params: query});
  }

  public createWebcam(payload): Observable<any> {
    return this.http.post('webcams', payload);
  }

  public updateWebcam(entityId, payload): Observable<any> {
    return this.http.put(`webcams/${entityId}`, payload);
  }

  public getWebcamData(payload): Observable<any> {
    return this.http.post('webcams/data', payload);
  }

  public downloadCsv(payload) {
    return this.http.post('webcams/csv', payload,{
      responseType: 'blob',
      observe: 'response'
    });
  }

  public getLiveCount(query) {
    return this.http.get('webcams/live', {params: {filter: query}});
  }

  public restoreWebcamData(payload) {
    return this.http.post('webcams/restore', payload);
  }
}
