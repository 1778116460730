import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { EntitiesDataService } from '../../../core/services/data/entities.data.service';
import { StorageService } from '../../../core/services/helpers/storage.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-entity-selector-dialog',
  templateUrl: './entity-selector-dialog.component.html',
  styleUrls: ['./entity-selector-dialog.component.scss']
})
export class EntitySelectorDialogComponent implements OnInit {

  public method = 'GET';
  public entities;
  public user;
  public entitiesLoaded = false;
  public entitiesForm: FormGroup

  constructor(
    private entitiesDataService: EntitiesDataService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<EntitiesDataService>
  ) { }

  ngOnInit(): void {
    this.user = this.storageService.get('user');
    this.entitiesForm = this.formBuilder.group({
      entityId: [null, [Validators.required]]
    });
    const entitiesFilter = this.buildEntitiesFilter();
    this.entitiesDataService.getEntities(entitiesFilter, this.method).subscribe((entities) => {
      this.entities = entities;
      this.entitiesLoaded = true;
    });
  }

  public buildEntitiesFilter() {
    if (this.user.allowedEntities) {
      this.method = 'POST';
      const entityIds = [...new Set(this.user.allowedEntities.map(entity => entity.id))];
      return {filter: {_id: {$in: entityIds}}};
    } else {
      this.method = 'GET';
      return {filter: JSON.stringify({})};
    }
  }

  public onSubmit() {
    if (this.entitiesForm.valid) {
      const entityId = this.entitiesForm.value.entityId;
      this.router.navigate([`/display/${entityId}`]).then(() => {
        this.dialogRef.close();
      });
    }
  }

}
