import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProductsDataService {
  constructor(
    private http: HttpClient
  ) { }

  public getProducts(query = {filter: '{}'}) {
    return this.http.get('products', {params: query});
  }

  public createProduct(payload) {
    return this.http.post('products', payload);
  }

  public updateProduct(productId, payload) {
    return this.http.put(`products/${productId}`, payload);
  }

  public deleteProduct(productId) {
    return this.http.delete(`products/${productId}`);
  }
}
