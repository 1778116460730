<form [formGroup]="topForm" (ngSubmit)="onSubmit()" fxFlex="100%" fxLayoutAlign="space-between" class="header__container" fxLayout="column">
  <mat-form-field appearance="outline" fxFlex="50%" fxFlex.lt-md="60%" fxFlex.lt-sm="100%">
    <mat-label>Select Performance</mat-label>
    <mat-select formControlName="type">
      <mat-option *ngFor="let orientation of orientations"
                  [value]="orientation.value"
      >
        {{orientation.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" fxFlex="50%" fxFlex.lt-md="60%" fxFlex.lt-sm="100%">
    <mat-label>Number of Locations</mat-label>
    <mat-select formControlName="amount">
      <mat-option *ngFor="let amount of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="amount">
        {{amount}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="primary" class="generate__button">Select</button>
</form>
