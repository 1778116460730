import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SnackbarService } from '../../../core/services/helpers/snackbar.service';
import { NotificationsDataService } from '../../../core/services/data/notifications.data.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { StorageService } from '../../../core/services/helpers/storage.service';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  public feedbackForm: FormGroup;
  public title: string;
  public type: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private notificationsDataService: NotificationsDataService,
    private snackbarService: SnackbarService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('50%', '50%');

    this.feedbackForm = this.formBuilder.group({
      description: ['', [Validators.required]],
    });

    switch (this.data.type) {
      case 'bug':
        this.title = 'Report a problem';
        this.type = 'problem report';
        break;
      case 'feedback':
        this.title = 'Submit feedback';
        this.type = 'feedback submission';
        break;
      case 'feature':
        this.title = 'Request a feature';
        this.type = 'feature request';
    }
  }

  onSubmit() {
    if (this.feedbackForm.valid) {
      const body = `Sender: ${this.storageService.get('user').email}.\n\n${this.feedbackForm.value.description}`;
      this.notificationsDataService.handleUserRequest({subject: this.data.title, body})
        .pipe(
          catchError((err) => {
            this.snackbarService.showErrorMessage('There was an error send your request. Please try again.');
            this.dialogRef.close();
            return throwError(err);
          })
        )
        .subscribe(() => {
          this.snackbarService.showSuccessMessage(`We have received your ${this.type}. Thank you for helping make PCount better.`);
          this.dialogRef.close();
        });
    }
  }

}
